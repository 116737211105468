import React from "react";
import { daStore } from "../store";
import { ContentItemPlayer } from "./ContentItemPlayer.jsx";

export const ContentItem = (props) => {
  const { title, text, pos } = props.item;
  const text_before = props?.item?.text_before ?? false;
  const [currentContentPlayerFile, setCurrentContentPlayerFile] =
    daStore.useState("currentContentPlayerFile");
  const [currentHoverObjectID, setCurrentHoverObjectID] =
    daStore.useState("currentHoverObjectID");

  const [contentIsActive, setContentIsActive] = React.useState(false);
  const [contentIsHover, setContentIsHover] = React.useState(false);

  React.useEffect(() => {
    if (window.debug) {
      console.log(currentContentPlayerFile);
    }
    if (pos === currentContentPlayerFile) {
      setContentIsActive(true);
    } else {
      setContentIsActive(false);
    }
  }, [currentContentPlayerFile]);

  React.useEffect(() => {
    if (window.debug) {
      console.log(currentHoverObjectID);
    }
    if (pos === currentHoverObjectID) {
      setContentIsHover(true);
      if (window.debug) {
        console.log('hit on: ' + pos);
      }
    } else {
      setContentIsHover(false);
    }
  }, [currentHoverObjectID]);

  return (
    <div id={"content-item-" + props.item.pos } className={"content-item" + (contentIsActive ? " active" : "") + (contentIsHover ? " active" : "")} onMouseEnter={() => setCurrentHoverObjectID(parseInt(props.item.pos))} onMouseLeave={() => setCurrentHoverObjectID(false)}>
      <ContentItemPlayer item={props.item}></ContentItemPlayer>
      {text_before && 
        <div className="content-text-before">{text_before}</div>
      }
      <div className="content-title">{title}</div>
      <div
        className="content-text"
        dangerouslySetInnerHTML={{ __html: text.replaceAll('##PAUSE##', '') }}
      ></div>
    </div>
  );
};
