import React from "react";
import ReactDOM from "react-dom";
import "./styles.scss";
import "./js/store";

import { App } from "./js/App.jsx";

document.addEventListener("DOMContentLoaded", function () {
  const RootElement = document.getElementById("root");
  ReactDOM.render(
    <App {...RootElement.dataset} />,
    document.getElementById("root")
  );
});
