import { createStore } from "state-pool";

export const daStore = createStore();
window.debug = process.env.NODE_ENV === "development";
window.howlPlayer = false;
daStore.setState("isBoarded", "de");
daStore.setState("lang", "de");
daStore.setState("isBoarded", false);
daStore.setState("arError", false);
daStore.setState("playerIsPlaying", false);
daStore.setState("playerFile", false);
daStore.setState("triggerPlayer", false);
daStore.setState("webHideObjects", true);
daStore.setState("currentContentPlayerFile", false);
daStore.setState("currentHoverObjectID", false);
