import React from "react";
import { daStore } from "../store";

function getFullImagePath(imagefile) {
  return "/src_ar/overlay-real/" + imagefile + ".png";
}

export const FocusObject = (props) => {
  const closeAble = props?.closeable == 1 ? true : false;
  const [triggerPlayer, setTriggerPlayer] = daStore.useState("triggerPlayer");
  const [lang, setLang] = daStore.useState("lang");
  const [currentContentPlayerFile, setCurrentContentPlayerFile] =
    daStore.useState("currentContentPlayerFile");

  const [objectIsLoaded, setObjectIsLoaded] = React.useState(false);

  React.useEffect(() => {
    if (window.debug) {
      console.log(triggerPlayer);
    }
    if (triggerPlayer?.playerState == "load_play") {
      if (window.debug) {
        console.log("we want to load_play");
      }
      if (!objectIsLoaded) {
        setObjectIsLoaded(triggerPlayer?.playerFile);
      } else {
        if (window.debug) {
          console.log("we are already loaded");
        }
      }
    }

    if (triggerPlayer?.playerState == "unload_stop") {
      if (window.debug) {
        console.log("we want to unload_stop");
      }
      if (objectIsLoaded) {
        setObjectIsLoaded(false);
      } else {
        if (window.debug) {
          console.log("we are already unloaded");
        }
      }
    }
  }, [triggerPlayer]);

  React.useEffect(() => {
    if (window.debug) {
      console.log(currentContentPlayerFile);
    }
    if (currentContentPlayerFile) {
      if (window.debug) {
        console.log("we are loading an focus object");
      }
      if (!objectIsLoaded || objectIsLoaded != currentContentPlayerFile) {
        setObjectIsLoaded(currentContentPlayerFile);
      } else {
        if (window.debug) {
          console.log("we are already loaded");
        }
      }
    } else {
      if (objectIsLoaded) {
        setObjectIsLoaded(false);
      } else {
        if (window.debug) {
          console.log("we are already unloaded");
        }
      }
    }
  }, [currentContentPlayerFile]);

  if (!objectIsLoaded) {
    return null;
  }

  const handleClose = () => {
    if (window.debug) {
      console.log("click close (top banner)");
    }

    if(closeAble){
    if (window.howlPlayer) {
      window.howlPlayer.unload();
    }
    //setObjectIsLoaded(false);
    setTriggerPlayer({ playerState: 'unload_stop', });
    setCurrentContentPlayerFile(false);
    }
  };

  return (
    <>
      <div className="focus-object-image" onClick={() => handleClose()}>
        <img src={getFullImagePath(objectIsLoaded)} />
      </div>
      <div className="focus-object"></div>
      <div className="focus-close-object" onClick={() => handleClose()} ></div>
    </>
  );
};