import React from "react";
import { Onboarding } from "./components/Onboarding.jsx";
import { ArApp } from "./components/ArApp.jsx";
import { WebsiteApp } from "./components/WebsiteApp.jsx";
import { Header } from "./components/Header.jsx";
import { daStore } from "./store";

export const App = (props) => {
  const [isBoarded, setIsBoarded] = daStore.useState("isBoarded");

  /*{ !isBoarded && props.mode === 'interactive' && <Onboarding />}

    { isBoarded && props.mode === 'interactive' && <ArApp />}  
    */

  return (
    <div className={"app app-mode-" + props.mode}>
      <Header mode={props.mode} />

      {!isBoarded && props.mode === "interactive" && <Onboarding />}

      {isBoarded && props.mode === "interactive" && <ArApp />}

      {props.mode === "website" && <WebsiteApp />}
    </div>
  );
};
