import React from "react";
import { daStore } from "../store";
import { Howl, Howler } from "howler";

//let interval = undefined;

function getFullFilePath(soundfile, lang) {
  let lang_short = "";
  if (lang == "en") {
    lang_short = "E";
  }
  if (lang == "de") {
    lang_short = "D";
  }
  return "/src_ar/sounds/" + soundfile + "_" + lang_short + ".mp3";
}

export const ContentItemPlayer = (props) => {
  const { pos } = props.item;

  const [triggerPlayer, setTriggerPlayer] = daStore.useState("triggerPlayer");
  const [lang, setLang] = daStore.useState("lang");
  const [currentContentPlayerFile, setCurrentContentPlayerFile] =
    daStore.useState("currentContentPlayerFile");
  const [currentHoverObjectID, setCurrentHoverObjectID] =
    daStore.useState("currentHoverObjectID");

  const [playerIsLoaded, setPlayerIsLoaded] = React.useState(false);
  const [playerIsPaused, setPlayerIsPaused] = React.useState(false);
  const [playerIsPlaying, setPlayerIsPlaying] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [thisInterval, setThisInterval] = React.useState(false);
  const [contentIsHover, setContentIsHover] = React.useState(false);
  //const [isAnimated, setIsAnimated] = React.useState(true);
  

  const handleTogglePlay = () => {
    if (window.debug) {
      console.log("click play/pause");
    }

    let globalPlayerIsPlaying = false;
    let localIsGlobal = false;

    if (window.howlPlayer) {
      setPlayerIsLoaded(true);
    }

    if (window.howlPlayer && window.howlPlayer.playing()) {
      globalPlayerIsPlaying = true;
    } else if (window.howlPlayer && !window.howlPlayer.playing()) {
      globalPlayerIsPlaying = false;
    }

    if (pos === currentContentPlayerFile) {
      localIsGlobal = true;
    } else {
      localIsGlobal = false;
    }

    if (!playerIsLoaded && !globalPlayerIsPlaying) {
      //init player
      if (window.debug) {
        console.log("initPlayer");
      }
      let playerFileFull = getFullFilePath(pos, lang);
      window.howlPlayer = new Howl({
        src: [playerFileFull],
      });
      setPlayerIsLoaded(true);
      setCurrentContentPlayerFile(pos);
      window.howlPlayer.play();
      setPlayerIsPlaying(true);
      setPlayerIsPaused(false);
    } else if (localIsGlobal) {
      if (window.debug) {
        console.log("localisglboal");
      }
      if (globalPlayerIsPlaying) {
        window.howlPlayer.pause();
        setPlayerIsPlaying(false);
        setPlayerIsPaused(true);
      } else {
        window.howlPlayer.play();
        setPlayerIsPlaying(true);
        setPlayerIsPaused(false);
      }
    } else if (!localIsGlobal) {
      if (window.debug) {
        console.log("global");
      }
      window.howlPlayer.unload();
      let playerFileFull = getFullFilePath(pos, lang);
      window.howlPlayer = new Howl({
        src: [playerFileFull],
      });
      setPlayerIsLoaded(true);
      setCurrentContentPlayerFile(pos);
      window.howlPlayer.play();
      setPlayerIsPlaying(true);
      setPlayerIsPaused(false);
    }
  };

  const handleClose = () => {
    if (window.debug) {
      console.log("click close");
    }

    if (window.howlPlayer) {
      window.howlPlayer.unload();
    }

    setPlayerIsLoaded(false);
    setPlayerIsPlaying(false);
    setPlayerIsPaused(true);
    setProgress(0);
    setCurrentContentPlayerFile(false);
    //setTriggerPlayer({ playerState: 'unload_stop', });
  };

  React.useEffect(() => {
    if (playerIsLoaded) {
      setThisInterval(
        setInterval(() => {
          if (window.howlPlayer.playing()) {
            setProgress(
              (window.howlPlayer.seek() / window.howlPlayer.duration()) * 100
            );
          }
        }, 50)
      );
    } else {
      if (window.debug) {
        console.log("clear interval for " + pos);
      }
      clearInterval(thisInterval);
    }
  }, [playerIsLoaded]);

  React.useEffect(() => {
    if (window.debug) {
      console.log(currentContentPlayerFile);
    }

    if (!currentContentPlayerFile || currentContentPlayerFile != pos) {
      if (window.debug) {
        console.log("unload because: " + currentContentPlayerFile);
      }
      setPlayerIsLoaded(false);
      setPlayerIsPlaying(false);
      setPlayerIsPaused(true);
      setProgress(0);
    }
  }, [currentContentPlayerFile]);

  React.useEffect(() => {
    if (window.debug) {
      console.log(triggerPlayer);
    }

    if (triggerPlayer && triggerPlayer.playerState == 'load_play_banner' && triggerPlayer.playerFile == pos  ) {
      setCurrentContentPlayerFile(triggerPlayer.playerFile);
      handleTogglePlay();
    }

  }, [triggerPlayer]);


  React.useEffect(() => {
    if (window.debug) {
      console.log(currentHoverObjectID);
    }
    if (pos === currentHoverObjectID) {
      setContentIsHover(true);
      if (window.debug) {
        console.log('hit on: ' + pos);
      }
    } else {
      setContentIsHover(false);
    }
  }, [currentHoverObjectID]);

  return (
    <div className={"content-player" + (playerIsLoaded ? " active" : "")}>
      <div className="content-player-top">
        <img
          className={
            "content-object-shape content-object-shape-inactive" +
            (!playerIsLoaded ? " active" : "")
          }
          src={"/src_ar/overlay/columns_" + pos + "_black.svg"}
          onClick={() => handleTogglePlay()}
        />
        <img
          className={
            "content-object-shape content-object-shape-active" +
            (playerIsLoaded ? " active" : "") + (contentIsHover ? " active" : "")
          }
          src={"/src_ar/overlay/columns_" + pos + ".svg"}
          onClick={() => handleTogglePlay()}
        />
      </div>
      <div className={"content-player-mid"}>
        <div
          className="content-player-progressbar"
          style={{ width: progress + "%" }}
        ></div>
        <div className="content-player-bar"></div>
      </div>
      <div className="content-player-bottom">
        <div
          className={
            "play-button button " +
            (playerIsLoaded && !playerIsPaused ? " paused" : "")
          }
          onClick={() => handleTogglePlay()}
        ></div>
        <div
          className="close-button button"
          onClick={() => handleClose()}
        ></div>
      </div>
    </div>
  );
};
