import React from "react";
import { daStore } from "../store";
import { Howl, Howler } from "howler";
import { PlayerStrings } from "./PlayerStrings";
import contentItems from "../ContentItems.json";

let interval = undefined;

function getFullFilePath(soundfile, lang) {
  let lang_short = "";
  if (lang == "en") {
    lang_short = "E";
  }
  if (lang == "de") {
    lang_short = "D";
  }
  return "/src_ar/sounds/" + soundfile + "_" + lang_short + ".mp3";
}

export const Player = (props) => {
  const [triggerPlayer, setTriggerPlayer] = daStore.useState("triggerPlayer");
  const [lang, setLang] = daStore.useState("lang");

  const [playerIsLoaded, setPlayerIsLoaded] = React.useState(false);
  const [playerIsPaused, setPlayerIsPaused] = React.useState(false);
  const [progress, setProgress] = React.useState(0);
  const [playerText, setPlayerText] = React.useState(false);
  const [duration, setDuration] = React.useState(0);
  const [isAnimated, setIsAnimated] = React.useState(true);

  const handleTogglePlay = () => {
    if (window.debug) {
      console.log("click play/pause");
    }

    if (window.howlPlayer) {
      if (playerIsPaused) {
        window.howlPlayer.play();
      } else {
        window.howlPlayer.pause();
      }
    }
  };

  const handleClose = () => {
    if (window.debug) {
      console.log("click close");
    }

    if (window.howlPlayer) {
      window.howlPlayer.unload();
    }

    setPlayerIsLoaded(false);
    setPlayerIsPaused(true);
    setPlayerText(false);
    setIsAnimated(false);
    setDuration(0);
    setProgress(0);

    setTriggerPlayer({ playerState: "unload_stop" });
  };

  React.useEffect(() => {
    if (playerIsLoaded) {
      interval = setInterval(() => {
        if (window.howlPlayer.playing()) {
          setProgress(
            (window.howlPlayer.seek() / window.howlPlayer.duration()) * 100
          );
        }
      }, 50);
    } else {
      clearInterval(interval);
    }
  }, [playerIsLoaded]);

  React.useEffect(() => {
    if (window.debug) {
      console.log(triggerPlayer);
    }

    if (triggerPlayer?.playerState == "load_play") {
      if (window.debug) {
        console.log("we want to play");
      }
      if (!playerIsLoaded) {
        //setPlayerFile(triggerPlayer?.playerFile);

        let playerFileFull = getFullFilePath(triggerPlayer?.playerFile, lang);
        window.howlPlayer = new Howl({
          src: [playerFileFull],
        });
        window.howlPlayer.on("load", function () {
          setDuration(window.howlPlayer.duration());
        });
        window.howlPlayer.on("end", function () {
          setProgress(0);
          setPlayerIsPaused(true);
          setIsAnimated(false);
          //clearInterval(interval);
        });

        window.howlPlayer.on("play", function () {
          setIsAnimated(true);
          setPlayerIsPaused(false);
        });

        window.howlPlayer.on("pause", function () {
          setPlayerIsPaused(true);
        });

        let this_content_item = contentItems[lang].filter((obj) => {
          return obj.pos === triggerPlayer?.playerFile;
        });
        setPlayerText({
          __html:
            '<span class="title">' +
            this_content_item[0]?.title +
            "</span>" +
            this_content_item[0]?.text?.replaceAll(
              "<br/>",
              '<span class="spacer"></span>'
            ).replaceAll('##PAUSE##', '<span class="break-spacer"></span>'),
        });

        setPlayerIsLoaded(true);

        if (window.debug) {
          console.log("++++++++ ok lets play");
        }
        window.howlPlayer.play();
        setIsAnimated(true);
      } else {
        if (window.debug) {
          console.log("we are already loaded");
        }
      }
    }
  }, [triggerPlayer]);

  PlayerStrings.setLanguage(lang);

  return (
    <div className="player">
      <div className="player-left">
        <div className="player-text-wrapper">
          {!playerText && (
            <div className="player-text">{PlayerStrings.click_object}</div>
          )}
          {playerText && (
            <div
              className={
                "player-text" +
                (isAnimated ? " animated" : "") +
                (!playerIsLoaded || playerIsPaused ? " paused" : "")
              }
              dangerouslySetInnerHTML={playerText}
              style={{ animationDuration: duration + "s" }}
            ></div>
          )}
        </div>
        <div
          className="player-progressbar"
          style={{ width: progress + "%" }}
        ></div>
      </div>
      <div className="player-right">
        <div
          className={
            "play-button button " +
            (playerIsLoaded && !playerIsPaused ? " paused" : "")
          }
          onClick={() => handleTogglePlay()}
        ></div>
        <div
          className="close-button button"
          onClick={() => handleClose()}
        ></div>
      </div>
    </div>
  );
};
