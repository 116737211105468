import React from "react";

export const CvText = ({lang}) => {
    if(lang === 'de'){
        return(
            <>
Ilit Azoulay (*1972 in Tel Aviv-Jaffa, Israel) lebt und arbeitet in Berlin. Ihre großformatigen Fotomontagen beruhen auf Bildmaterial, das sie im Rahmen umfangreicher Recherchen zusammenträgt. Indem Azoulay mit ihren Arbeiten eine Verschiebung der fotografischen Perspektive vornimmt, enthüllt sie verborgene visuelle Informationen und eröffnet alternative Sichtweisen innerhalb vertrauter Wissensgebiete. Azoulays künstlerische Praxis ist kontextbezogen und umfasst neben dem Medium Fotografie auch konzeptionelle Anteile sowie architektonische Interventionen und Sound-, Video- oder Performanceelemente, weshalb ihr Œuvre oft  als intermediale und genreübergreifende Synthese wahrgenommen wird.<br/>
<br/>
Azoulays Arbeiten wurden international in zahlreichen Institutionen gezeigt. Mit Queendom, ihrem jüngsten Projekt, bespielte sie kürzlich den israelischen Pavillon auf der 59. Biennale von Venedig (2022). Sie hatte Einzelausstellungen im Herzliya Museum of Contemporary Art, Israel (2014), in den KW Institute for Contemporary Art, Berlin (2014), in Das Israel Museum, Jerusalem (2017), im CCA – Center for Contemporary Art, Tel Aviv (2019) sowie in der Braverman Gallery, Tel Aviv (2021). Darüber hinaus waren ihre Arbeiten in Gruppenausstellungen in international renommierten Institutionen zu sehen, darunter Das Israel Museum, Jerusalem (2011, 2017), Daimler Contemporary, Berlin (2012, 2020), das Museum of Modern Art, New York (2015), das Musée d’Art moderne de la Ville de Paris (2015), das Australian Centre for Contemporary Art, Melbourne (2015), die Pinakothek der Moderne, München (2016), das Bauhaus Museum, Dessau (2019), das Eretz Israel Museum, Tel Aviv (2021) sowie das Kunsthaus Zürich (2022).<br/>
<br/>
Azoulays Werke wurden in vielzählige internationale Museums- und Privatsammlungen aufgenommen, darunter die des Museum of Modern Art, New York, des Guggenheim Museum, New York, des LACMA, Los Angeles, des Hammer Museum, Los Angeles, des Art Institute of Chicago, des Centre Georges Pompidou, Paris, der National Gallery of Australia, Canberra, der Julia Stoschek Collection, Düsseldorf/Berlin, des Das Israel Museum, Jerusalem, sowie des Tel Aviv Museum of Art.<br/>
<br/>
Azoulay absolvierte ein Studium an der Bezalel Academy of Arts and Design in Jerusalem. Ihr wurden mehrere Preise verliehen, darunter der Constantiner Photography Award for an Israeli Artist des Tel Aviv Museum of Art (2011), der Preis des israelischen Ministeriums für Kultur und Sport (2011, 2017) sowie der Rencontres d’Arles Discovery Award (2014). Darüber hinaus war sie unter den Finalist*innen des Prix Pictet (2015).<br/>
<br/>
Zu ihren jüngsten Veröffentlichungen zählen das Künstlerinnenbuch Finally Without End (Sternberg Press, Berlin 2014), der zur gleichnamigen Ausstellung in den Berliner KW erschienene Band Shifting Degrees of Certainty (Spector Books, Leipzig 2014), die Monografie No Thing Dies (Mousse Publishing, Mailand 2019), die im Anschluss an ihre gleichnamige Ausstellung im Das Israel Museum, Jerusalem, veröffentlicht wurde, sowie Queendom (Distanz Verlag, Berlin 2022), die Begleitpublikation zu ihrer Schau auf der Biennale von Venedig.<br/>
            </>
        )
    }
    if(lang === 'en'){
        return(
            <>
Ilit Azoulay (b. 1972 in Jaffa–Tel Aviv, Israel; lives and works in Berlin) makes large-scale photomontages composed of images obtained through extensive research. Altering the photographic perspective, these works uncover unseen visual information and enable alternative vantage points within familiar fields of knowledge. Azoulay’s artistic practice is contextual and may often be perceived as a Gesamtkunstwerk comprising, in addition to photography, conceptual components, architectural interventions, and elements of sound, video, or performance.<br/>
<br/>
Azoulay’s work has been exhibited extensively around the world, including her most recent project, Queendom, for the Israeli Pavilion at the 59th Biennale Arte in Venice (2022). In addition, she has presented solo exhibitions at Herzliya Museum of Contemporary Art, Herzliya, Israel (2014); KW Institute for Contemporary Art, Berlin (2014); The Israel Museum, Jerusalem (2017); CCA – Center for Contemporary Art, Tel Aviv (2019); and Braverman Gallery, Tel Aviv (2021). She has also participated in group exhibitions in major international venues, including The Israel Museum, Jerusalem (2011, 2017); Daimler Contemporary, Berlin (2012, 2020); The Museum of Modern Art, New York (2015); Musée d’Art moderne de la Ville de Paris, Paris (2015); Australian Centre for Contemporary Art, Melbourne (2015); Pinakothek der Modern, Munich (2016); Bauhaus Museum, Dessau (2019); the Eretz Israel Museum, Tel Aviv (2021); Kunsthaus Zurich (2022).<br/>
<br/>
Azoulay’s works are held in numerous museum and private collections worldwide, including the Museum of Modern Art, New York; Guggenheim Museum, New York; LACMA, Los Angeles; Hammer Museum, Los Angeles; the Art Institute of Chicago; Centre Georges Pompidou, Paris; National Gallery of Australia, Canberra; Julia Stoschek Collection, Berlin; The Israel Museum, Jerusalem; and Tel Aviv Museum of Art, Tel Aviv.<br/>
<br/>
Azoulay studied at the Bezalel Academy of Arts and Design, Jerusalem. She is the recipient of several awards, including the Constantiner Photography Award for an Israeli Artist, Tel Aviv Museum of Art (2011), the Israel Ministry of Culture and Sports Prize (2011, 2017); and the Rencontres d’Arles Discovery Award (2014); and was among the Prix Pictet Finalists (2015).<br/>
<br/>
Recent publications include the artist-book Finally Without End (Sternberg Press, Berlin, 2014); Shifting Degrees of Certainty (Spector Books, Leipzig, 2014), which accompanied her exhibition at KW, Berlin; the monograph No Thing Dies (Mousse Publishing, Milan, 2019), published following her eponymous exhibition at The Israel Museum, Jerusalem; and the publication Queendom (Distanz Verlag, Berlin, 2022), accompanying her exhibition at the Venice Biennale Arte.<br/>
            </>
        )
    }

    return null;

}