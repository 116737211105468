import React from "react";
import { createStore } from "state-pool";
import { daStore } from "../store";
import { HeaderStrings } from "./HeaderStrings";

export const Header = (props) => {
  const [lang, setLang] = daStore.useState("lang");
  const [webHideObjects, setWebHideObjects] =
    daStore.useState("webHideObjects");

  //const [_, forceUpdate] = React.useReducer((x) => x + 1, 0);

  const handleLanguageSelect = (lang) => {
    setLang(lang);
    //forceUpdate();
  };

  const handleToggleHideObjects = () => {
    setWebHideObjects(!webHideObjects);
  };

  HeaderStrings.setLanguage(lang);

  if (props.mode == "interactive") {
    return (
      <div className="header">
        <div className="header-title">
          <a href="/website">Digital Amnesia or Constructed Memory</a>
        </div>
        <div className="header-lang-select">
          <div
            className={"lang lang-de" + (lang === "de" ? " active" : "")}
            onClick={() => handleLanguageSelect("de")}
          >
            D<span className="long">Deutsch</span>
          </div>
          <div
            className={"lang lang-en" + (lang === "en" ? " active" : "")}
            onClick={() => handleLanguageSelect("en")}
          >
            E<span className="long">English</span>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="website-header">
      <span className="click-me"onClick={() => {
          window.scroll({
            behavior: 'smooth',
            left: 0,
            top: document.getElementById('about-content').offsetTop - document.getElementById("objects").offsetHeight - 80
          });
        }}>Digital Amnesia or
      <br />
      Constructed Memory
      <br />
      <br />
      Ilit Azoulay</span>
      <br />
      <br />
      
      <div className="header-right">
        <div
          className={"hide-objects" + (webHideObjects ? " hidden" : "")}
          onClick={() => handleToggleHideObjects()}
        >
          <span className="line"></span>
          { lang === 'de' &&
            <>
              {HeaderStrings.objects} <span className="hide"> {HeaderStrings.hide}</span>
              <span className="show"> {HeaderStrings.show}</span>
            </>
          }
          { lang === 'en' &&
            <>
              <span className="hide">{HeaderStrings.hide}</span>
              <span className="show">{HeaderStrings.show}</span> {HeaderStrings.objects}
            </>
          }
        </div>
        <div className="header-lang-select">
          <div
            className={"lang lang-de" + (lang === "de" ? " active" : "")}
            onClick={() => handleLanguageSelect("de")}
          >
            <span className="short">D</span>
            <span className="long">Deutsch</span>
          </div>
          <div
            className={"lang lang-en" + (lang === "en" ? " active" : "")}
            onClick={() => handleLanguageSelect("en")}
          >
            <span className="short">E</span>
            <span className="long">English</span>
          </div>
        </div>
      </div>
    </div>
  );
};
