import React from "react";

export const AboutText = ({lang}) => {
    if(lang === 'de'){
        return(
            <>
In ihrem Projekt Digital Amnesia or Constructed Memory verwendet die Künstlerin Ilit Azoulay die monumentalen Hallen des Münchner Maximiliansgymnasiums als visuelles Ausgangsmaterial, um die unzähligen überlieferten und nicht-überlieferten Geschichten dieses Ortes zu bewahren. Mittels einer Kombination aus Fotografie, Bildhauerei und Sound werden die Überreste des Rohbaus des Gymnasiums in Form eines von einem Steinrahmen eingefassten Panoramabildes visualisiert. Diese anlässlich der Restaurierung dieses historischen Jugendstilgebäudes im Herzen von München entstandene Arbeit beleuchtet die Geschichte und das Erbe des Maximilian-Gymnasiums als deutsche Institution, beginnend ihrer Gründung im Jahr 1849. Digital Amnesia or Constructed Memory gewährt einen modifizierten Blick auf die rekonstruierten Innenräume der Schule und beleuchtet so das beständige Fundament von Traditionen, das letztlich auf einer vulnerablen physischen Infrastruktur basiert. Indem Azoulay die architektonischen Elemente mit den für sie charakteristischen fotografischen Mitteln konserviert, hebt sie die problematischen Aspekte des Erbes der Schule hervor. Diese werden zwar für gewöhnlich nicht an die Öffentlichkeit getragen, sind aber Teil der Geschichte, auf die die Schule mit Stolz zurückblickt.<br/>
<br/>
Die Arbeit umfasst eine Panorama-Fotomontage aus rund 3.000 mit einem Makroobjektiv aufgenommenen Fotografien sowie zeitgenössische Interpretationen altbekannter bayerischer Hymnen und Volkslieder, die von den Besucher*innen über Augmented-Reality-Technologien abgerufen werden können, wodurch die Grenzen des Mediums der Fotografie erweitert werden. Einmalige Verse, geformt aus einer Reihe von Interviews mit Dutzenden von Alumni, die sich bereiterklärt hatten, ihre Erinnerungen an ihre Zeit an der Schule zu teilen, wurden den Refrains dieser traditionellen Lieder beigefügt. Das kollektive Beharren der meisten Absolvent*innen, die wiederholt betonen, sich stark mit der Schule verbunden zu fühlen, stellt letztlich eine unüberwindbare Barriere für Azoulays Recherche dar. Die Verbundenheit der Ehemaligen mit den bayerischen Traditionen ist trotz der kulturellen, wirtschaftlichen, politischen und sozialen Umbrüche der vergangenen Jahre ungebrochen. Diese Berichte verbindet Azoulay zu einer Komposition, die von der Resilienz der Stimmen zeugt, die aus der Vergangenheit der Schule empordringen.<br/>
<br/>
Indem Azoulay in ihrem kritischen Ansatz die zusammengetragenen Geschichten mit den Klängen des traditionellen Bayern verknüpft, impliziert sie, dass immer etwas unter der Oberfläche schlummert, das nicht auf den ersten Blick ersichtlich ist. Digital Amnesia or Constructed Memory lässt die Betrachter*innen durch Erinnerungs- und Klangwelten driften und vermittelt einen Eindruck von der Entschlossenheit, mit der der traditionelle Charakter der Lobgesänge gepflegt wird.<br/>
<br/>
<span className="italic">Solang der Alte Peter<br/>
am Petersbergerl steht,<br/>
solang die grüne Isar<br/>
durchs Münchner Stadterl geht,<br/>
solang da drunt am Platz<br/>
noch steht das Hofbräuhaus,<br/>
solang stirbt die Gemütlichkeit<br/>
in München niemals aus,<br/>
solang stirbt die Gemütlichkeit<br/>
in München niemals aus.<br/>
</span>
            </>
        )
    }
    if(lang === 'en'){
        return(
            <>
Digital Amnesia or Constructed Memory presents the imposing halls of the Maximilian Gymnasium in Munich, as reimagined by the artist Ilit Azoulay, in a bid to preserve its myriad histories, both told and untold. The project combines photography, sculpture, and sound, to visually uncover what remains of the bare building in a panoramic work encased in a stone frame. On the occasion of the conservation of this historical Art-Nouveau building in the heart of Munich, Azoulay has produced a work that draws on the evolution of this German institution and its legacy since its founding in 1849. Digital Amnesia or Constructed Memory provides a tampered view of the school’s reassembled interiors, thereby revealing the stable foundations of tradition that is rooted in a vulnerable physical infrastructure. Azoulay’s practice echoes the physical conservation of the building by using her signature photographic tools to preserve architectural elements, as a means of underscoring the more problematic aspects of the school’s uplifting legacy—which, habitually kept behind closed doors, are also part of the foundation on which the school proudly stands.<br/>
<br/>
The work comprises a panoramic photomontage of some 3,000 photographs taken with a macro lens, alongside contemporary renditions of long-established Bavarian anthems and folk songs that are accessed through Augmented Reality technology, expanding the boundaries of photography. Unique verses that emerged from a series of interviews with dozens of alumni who agreed to recount their memories of their time at the school, were added to the basic choruses of these traditional songs. Azoulay’s research was met with a collective and repeated insistence of most of the graduates to convey a strong, positive and unassailable bond with the school, presenting an unbreachable barrier. Despite the turbulent shifts in cultural, economic, political, and social affairs over the years, their affinity to Bavarian traditions remains intact. Based on their contributions, Azoulay’s composition reflects the resilience of the voices emerging from the school’s past.<br/>
<br/>
Exposing an earnest determination to preserve the nature of the traditional hymns of praise, Azoulay’s critical approach merges the collected stories with the resonating sounds of traditional Bavaria—suggesting, through the realms of memory and sound, that there is always more than meets the eye.<br/>
<br/>
<span className="italic">
As long as ol’ Peter<br/>
on Petersbergl stands,<br/>
As long as the Isar<br/>
through Munich’s center bends,<br/>
As long as, down in the square,<br/>
the Hofbräuhaus still stands,<br/>
So long as the comfort of Munich<br/>
never ever dies,<br/>
So long as the comfort of Munich<br/>
never ever dies.<br/>
</span>
            </>
        )
    }

    return null;

}