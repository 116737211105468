import React, { useEffect, useRef } from "react";
import "mind-ar/dist/mindar-image.prod.js";
import "aframe";
import "mind-ar/dist/mindar-image-aframe.prod.js";
import { daStore } from "../store";

export default () => {
  const sceneRef = useRef(null);

  const [errorDetails, setErrorDetails] = daStore.useState("arError");
  const [isBoarded, setIsBoarded] = daStore.useState("isBoarded");
  const [triggerPlayer, setTriggerPlayer] = daStore.useState("triggerPlayer");
  //const [playerFile, setPlayerFile] = daStore.useState("playerFile");

  useEffect(() => {
    const sceneEl = sceneRef.current;
    const arSystem = sceneEl.systems["mindar-image-system"];

    /*sceneEl.addEventListener('renderstart', () => {
            //sceneEl.components.inspector.openInspector();
            arSystem.start(); // start AR 
        });*/

    if (window.debug) {
      console.log("rendering ar");
    }

    sceneEl.addEventListener("arReady", (event) => {
      if (window.debug) {
        console.log("MindAR is ready");
      }
      //sceneEl.components.inspector.openInspector();
    });

    sceneEl.addEventListener("arError", (event) => {
      if (window.debug) {
        console.log("MindAR failed to start");
      }
      setErrorDetails("AR Error");
      setIsBoarded(false);
    });

    const overlayElements = document.querySelectorAll(".playme");

    overlayElements.forEach((element) => {
      element.addEventListener("click", (event) => {
        setTriggerPlayer({
          playerState: "load_play",
          playerFile: parseInt(event.currentTarget.dataset.pos),
        });
        //arSystem.stop()
        //console.log('clicked: ' + event.currentTarget.dataset.pos);
      });
    });

    /*return () => {
            arSystem.stop();
        }*/
  }, []);

  return (
    <a-scene
      ref={sceneRef}
      mindar-image="imageTargetSrc: /src_ar/targets.mind; maxTrack: 1; autoStart: true;"
      vr-mode-ui="enabled: false"
      embedded
      color-space="sRGB"
      renderer="colorManagement: true, physicallyCorrectLights"
      device-orientation-permission-ui="enabled: false"
    >
      <a-assets>
        <img id="overlay-pos-1" src="/src_ar/overlay/columns_1.svg" />
        <img id="overlay-pos-2" src="/src_ar/overlay/columns_2.svg" />
        <img id="overlay-pos-3" src="/src_ar/overlay/columns_3.svg" />
        <img id="overlay-pos-4" src="/src_ar/overlay/columns_4.svg" />
        <img id="overlay-pos-5" src="/src_ar/overlay/columns_5.svg" />
        <img id="overlay-pos-6" src="/src_ar/overlay/columns_6.svg" />
        <img id="overlay-pos-7" src="/src_ar/overlay/columns_7.svg" />
        <img id="overlay-pos-8" src="/src_ar/overlay/columns_8.svg" />
        <img id="overlay-pos-9" src="/src_ar/overlay/columns_9.svg" />
        <img id="overlay-pos-10" src="/src_ar/overlay/columns_10.svg" />
        <img id="overlay-pos-11" src="/src_ar/overlay/columns_11.svg" />
        <img id="overlay-pos-12" src="/src_ar/overlay/columns_12.svg" />
        <img id="overlay-pos-13" src="/src_ar/overlay/columns_13.svg" />
        <img id="overlay-pos-14" src="/src_ar/overlay/columns_14.svg" />
        <img id="overlay-pos-15" src="/src_ar/overlay/columns_15.svg" />
        <img id="overlay-pos-16" src="/src_ar/overlay/columns_16.svg" />
        <img id="overlay-pos-17" src="/src_ar/overlay/columns_17.svg" />
        <img id="overlay-pos-18" src="/src_ar/overlay/columns_18.svg" />
        <img id="overlay-pos-19" src="/src_ar/overlay/columns_19.svg" />
        <img id="overlay-pos-20" src="/src_ar/overlay/columns_20.svg" />
        <img id="overlay-pos-21" src="/src_ar/overlay/columns_21.svg" />
        <img id="overlay-pos-22" src="/src_ar/overlay/columns_22.svg" />
      </a-assets>

      <a-camera
        position="0 0 0"
        look-controls="enabled: false"
        cursor="fuse: false; rayOrigin: mouse;"
        raycaster="far: 10000; objects: .clickable;"
      ></a-camera>

      <a-entity mindar-image-target="targetIndex: 0">
        <a-image
          data-pos="4"
          class="clickable playme"
          src="#overlay-pos-4"
          position="-0.285 -0.265 0"
          height="0.11"
          width="0.095"
          rotation="0 0 0"
        ></a-image>

        <a-image
          data-pos="5"
          class="clickable playme"
          src="#overlay-pos-5"
          position="0.19 -0.045 0"
          height="0.045"
          width="0.13"
          rotation="0 0 0"
        ></a-image>

        <a-image
          data-pos="10"
          class="clickable playme"
          src="#overlay-pos-10"
          position="-0.365 0 0"
          height="0.25"
          width="0.20"
          rotation="0 0 0"
        ></a-image>

        <a-image
          data-pos="12"
          class="clickable playme"
          src="#overlay-pos-12"
          position="-0.044 -0.147 0"
          height="0.11"
          width="0.07"
          rotation="0 0 0"
        ></a-image>

        <a-image
          data-pos="13"
          class="clickable playme"
          src="#overlay-pos-13"
          position="0.457 -0.115 0"
          height="0.23"
          width="0.185"
          rotation="0 0 0"
        ></a-image>

        <a-image
          data-pos="18"
          class="clickable playme"
          src="#overlay-pos-18"
          position="0.054 -0.042 0"
          height="0.82"
          width="0.09"
          rotation="0 0 0"
        ></a-image>

        <a-image
          data-pos="21"
          class="clickable playme"
          src="#overlay-pos-21"
          position="0.19 -0.37 0.001"
          height="0.045"
          width="0.075"
          rotation="0 0 0"
        ></a-image>
      </a-entity>

      <a-entity mindar-image-target="targetIndex: 1">
        <a-image
          data-pos="1"
          class="clickable playme"
          src="#overlay-pos-1"
          position="0.125 0.405 0"
          height="0.095"
          width="0.165"
          rotation="0 0 0"
        ></a-image>

        <a-image
          data-pos="2"
          class="clickable playme"
          src="#overlay-pos-2"
          position="0.21 0.21 -0.01"
          height="0.15"
          width="0.135"
          rotation="0 0 0"
        ></a-image>

        <a-image
          data-pos="3"
          class="clickable playme"
          src="#overlay-pos-3"
          position="-0.215 0.175 -0.01"
          height="0.39"
          width="0.36"
          rotation="0 0 0"
        ></a-image>

        <a-image
          data-pos="6"
          class="clickable playme"
          src="#overlay-pos-6"
          position="0.255 -0.11 -0.01"
          height="0.26"
          width="0.18"
          rotation="0 0 0"
        ></a-image>

        <a-image
          data-pos="7"
          class="clickable playme"
          src="#overlay-pos-7"
          position="0.105 -0.14 0"
          height="0.2"
          width="0.2"
          rotation="0 0 0"
        ></a-image>

        <a-image
          data-pos="11"
          class="clickable playme"
          src="#overlay-pos-11"
          position="-0.295 -0.142 0"
          height="0.115"
          width="0.07"
          rotation="0 0 0"
        ></a-image>

        <a-image
          data-pos="13"
          class="clickable playme"
          src="#overlay-pos-13"
          position="-0.548 -0.115 0"
          height="0.23"
          width="0.185"
          rotation="0 0 0"
        ></a-image>

        <a-image
          data-pos="19"
          class="clickable playme"
          src="#overlay-pos-19"
          position="0.49 -0.06 0"
          height="0.172"
          width="0.14"
          rotation="0 0 0"
        ></a-image>

        <a-image
          data-pos="22"
          class="clickable playme"
          src="#overlay-pos-22"
          position="0.45 -0.31 0"
          height="0.16"
          width="0.40"
          rotation="0 0 0"
        ></a-image>
      </a-entity>

      <a-entity mindar-image-target="targetIndex: 2">
        <a-image
          data-pos="8"
          class="clickable playme"
          src="#overlay-pos-8"
          position="-0.18 0.215 0"
          height="0.18"
          width="0.09"
          rotation="0 0 0"
        ></a-image>

        <a-image
          data-pos="9"
          class="clickable playme"
          src="#overlay-pos-9"
          position="-0.14 -0.31 0"
          height="0.20"
          width="0.35"
          rotation="0 0 0"
        ></a-image>

        <a-image
          data-pos="14"
          class="clickable playme"
          src="#overlay-pos-14"
          position="0.18 0.31 0"
          height="0.2"
          width="0.1"
          rotation="0 0 0"
        ></a-image>

        <a-image
          data-pos="15"
          class="clickable playme"
          src="#overlay-pos-15"
          position="-0.36 0.2 0"
          height="0.02"
          width="0.05"
          rotation="0 0 0"
        ></a-image>

        <a-image
          data-pos="16"
          class="clickable playme"
          src="#overlay-pos-16"
          position="-0.045 0.335 0"
          height="0.065"
          width="0.065"
          rotation="0 0 0"
        ></a-image>

        <a-image
          data-pos="17"
          class="clickable playme"
          src="#overlay-pos-17"
          position="0.34 -0.12 0"
          height="0.12"
          width="0.07"
          rotation="0 0 0"
        ></a-image>

        <a-image
          data-pos="19"
          class="clickable playme"
          src="#overlay-pos-19"
          position="-0.51 -0.06 0"
          height="0.172"
          width="0.14"
          rotation="0 0 0"
        ></a-image>

        <a-image
          data-pos="20"
          class="clickable playme"
          src="#overlay-pos-20"
          position="0.195 -0.13 0"
          height="0.28"
          width="0.12"
          rotation="0 0 0"
        ></a-image>

        <a-image
          data-pos="22"
          class="clickable playme"
          src="#overlay-pos-22"
          position="-0.54 -0.31 0"
          height="0.16"
          width="0.40"
          rotation="0 0 0"
        ></a-image>
      </a-entity>
    </a-scene>
  );
};
