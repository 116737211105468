import LocalizedStrings from "localized-strings";

export const OnboardingStrings = new LocalizedStrings({
  en: {
    welcome: "Augmented reality experience with audio.",
    access: "Please allow access to your camera!",
    track_error:
      "Another application (Zoom, Webex, ...) or browser tab (Google Meet, Messenger Video) might already be using your webcam. Please turn off other cameras before proceeding.",
    track_error_title: "Can't start your camera",
    user_denied_access: "App requires access to your camera.",
    user_denied_access_click: "Click the camera blocked icon ",
    user_denied_access_browser: " in your browser's address bar.",
    user_denied_access_title: "Camera is blocked",
    retry: "Retry",
    error_details: "Error Details",
    system_denied_browser:
      "Your browser might not have access to your camera. To fix this problem, open",
  },
  de: {
    welcome: "Augmented reality experience mit audio.",
    access: "Bitte die App auf die Kamera zugreifen lassen!",
    track_error:
      "Eine andere Anwendung (Zoom, Webex, ...) oder ein anderer Browsertab (Google Meet, Messenger Video) benutzt ihre Kamera schon. Bitte schalte andere Anwendungen welche die Kamera benutzen aus.",
    track_error_title: "Kamera kann nicht gestartet werden",
    user_denied_access: "Die App benötigt Zugriff auf ihre Kamera.",
    user_denied_access_click: "Klick auf das 'Kamera ist geblockt' Icon ",
    user_denied_access_browser: " in deiner Browser Adressleiste.",
    user_denied_access_title: "Kamera ist blockiert",
    retry: "Wiederholen",
    error_details: "Fehlerdetails",
    system_denied_browser:
      "Dein Browser hat keinen Zugriff auf deine Kamera. Um das Problem zu lösen, öffne ",
  },
});
