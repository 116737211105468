import React from "react";
import { daStore } from "../store";
import { ContentItem } from "./ContentItem.jsx";
import { WebsiteAppStrings } from "./WebsiteAppStrings";
import contentItems from "../ContentItems.json";
import { FocusObject } from "./FocusObject.jsx";
import { AboutText } from "./AboutText.jsx";
import { CvText } from "./CvText.jsx";

function debounce(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

export const WebsiteApp = (props) => {
  const [lang, setLang] = daStore.useState("lang");
  const [webHideObjects, setWebHideObjects] =
    daStore.useState("webHideObjects");
  const [triggerPlayer, setTriggerPlayer] = daStore.useState("triggerPlayer");
  const [currentHoverObjectID, setCurrentHoverObjectID] =
    daStore.useState("currentHoverObjectID");

  const [objectHeight, setObjectHeight] = React.useState(100);

  const onLoad = () => {
    const bla = document.getElementById("objects");
    setObjectHeight(bla.offsetHeight);
  };

  React.useEffect(() => {
    const debouncedHandleResize = debounce(function handleResize() {
      const bla = document.getElementById("objects");
      setObjectHeight(bla.offsetHeight);
    }, 300);

    window.addEventListener("resize", debouncedHandleResize);

    return (_) => {
      window.removeEventListener("resize", debouncedHandleResize);
    };
  });

  let contentItemsByLang = contentItems[lang];

  WebsiteAppStrings.setLanguage(lang);

  return (
      <div className="website-app">
          <div className="objects" id="objects">
              {contentItemsByLang &&
                  contentItemsByLang.map((contentItem, i) => {
                      return (
                          <img
                              className={
                                  "overlay-single overlay-single-" +
                                  (i + 1) +
                                  " " +
                                  (currentHoverObjectID == i + 1 ? "hover" : "")
                              }
                              src={
                                  "/src_ar/overlay/columns_" + (i + 1) + ".svg"
                              }
                              onClick={() => {
                                  setTriggerPlayer({
                                      playerState: "load_play_banner",
                                      playerFile: parseInt(i + 1),
                                  });
                                  let el_id = "content-item-" + (i + 1);
                                  window.scroll({
                                      behavior: "smooth",
                                      left: 0,
                                      top:
                                          document.getElementById(el_id)
                                              .offsetTop -
                                          document.getElementById("objects")
                                              .offsetHeight -
                                          80,
                                  });
                              }}
                              onMouseEnter={() =>
                                  setCurrentHoverObjectID(parseInt(i + 1))
                              }
                              onMouseLeave={() =>
                                  setCurrentHoverObjectID(false)
                              }
                          />
                      );
                  })}

              <img
                  className={
                      "overlay-objects-all" + (webHideObjects ? " hidden" : "")
                  }
                  src="/src/img/website_overlay_objects.svg"
              />
              <img src="/src/img/website_bg.jpg" onLoad={onLoad} />
              <FocusObject closeable="1" />
          </div>
          <div
              className="content-list"
              style={{ marginTop: objectHeight + "px" }}
          >
              {contentItemsByLang &&
                  contentItemsByLang.map((contentItem, i) => {
                      return (
                          <ContentItem key={i} item={contentItem}></ContentItem>
                      );
                  })}
          </div>
          <div id="about-content" className="about-content">
              <div className="about-content-item">
                  <div className="about-content-title">
                      Digital Amnesia or Constructed Memory
                  </div>
                  <div className="about-content-text">
                      <AboutText lang={lang}></AboutText>
                  </div>
              </div>

              <div className="about-content-item">
                  <div className="about-content-title">Ilit Azoulay</div>
                  <div className="about-content-text">
                      <CvText lang={lang}></CvText>
                  </div>
              </div>
          </div>
          <div className="about-content imprint-content">
              <div className="about-content-item">
                  <div className="about-content-title">
                      {WebsiteAppStrings.imprint}
                  </div>
                  <div className="about-content-text">
                      {lang === "de" && (
                          <>
                              Webdesign
                              <br />
                              Studio Laucke Siebein
                              <br />
                              <a
                                  href="https://studio-laucke-siebein.com"
                                  target="_blank"
                              >
                                  studio-laucke-siebein.com
                              </a>
                              <br />
                              <br />
                              Programmierer / AR
                              <br />
                              Clemens Buchegger
                              <br />
                              <a
                                  href="https://clemensbuchegger.com"
                                  target="_blank"
                              >
                                  clemensbuchegger.com
                              </a>
                              <br />
                              <br />
                              Projektmanagement
                              <br />
                              Katrin Aichele
                              <br />
                              Moranne Mintz
                              <br />
                              Christina Pethick
                              <br />
                              <br />
                              Künstlerische Produktionsleitung
                              <br />
                              Daniel Fusban
                              <br />
                              <br />
                              Recherche & Interviewkoordination
                              <br />
                              Ariana Berndl
                              <br />
                              <br />
                              Konzeptioneller Berater
                              <br />
                              Jonathan Touitou
                              <br />
                              <br />
                              Gedichte in Englisch und Deutsch
                              <br />
                              Abigail Willoughby
                              <br />
                              <br />
                              Steinmetz
                              <br />
                              Claus Rehm
                              <br />
                              <br />
                              Stimme des griechischen Texts
                              <br />
                              Christian Firsching
                              <br />
                              <br />
                              Audiobearbeitung
                              <br />
                              Frank Sauer
                              <br />
                              <br />
                              Projekttext
                              <br />
                              Estee Balsam
                              <br />
                              <br />
                              Bearbeitung des englischen Texts
                              <br />
                              Einat Adi
                              <br />
                              <br />
                              Englisches Lektorat
                              <br />
                              Magdalen Chua
                              <br />
                              <br />
                              Deutsches Lektorat
                              <br />
                              Katharina Wulffius
                              <br />
                              <br />
                              Juristische Beratung
                              <br />
                              Felix Stang
                              <br />
                              <br />
                              Auftraggeber
                              <br />
                              Baureferat der Landeshauptstadt München
                              <br />
                              Quivid – Kunst-am-Bau-Programm der Stadt München
                              <br />
                              <br />
                              Mitwirkende Student*innen:
                              <br />
                              Felix Aschenbrenner
                              <br />
                              Johanna Fuchs
                              <br />
                              Nana Kammann
                              <br />
                              Charlotte Lemke
                              <br />
                              Ekaterini Michales
                              <br />
                              Emilian Röckemann
                              <br />
                              Amélie-Nejd Saleh
                              <br />
                              Luis Schmieder
                              <br />
                              Pia Scheneider Gonzáles
                              <br />
                              Gabriela Tultschin
                              <br />
                              <br />
                              <br />
                              <br />
                              Wir danken allen Schüler*innen sowie der
                              Belegschaft und insbesondere den Lehrer*innen des
                              Maximilians-Gymnasiums. Mit ihrer Hilfe und
                              Unterstützung konnte dieses Projekt realisiert
                              werden.
                              <br />
                          </>
                      )}
                      {lang === "en" && (
                          <>
                              Web Design
                              <br />
                              Studio Laucke Siebein
                              <br />
                              <a
                                  href="https://studio-laucke-siebein.com"
                                  target="_blank"
                              >
                                  studio-laucke-siebein.com
                              </a>
                              <br />
                              <br />
                              Programmer / AR
                              <br />
                              Clemens Buchegger
                              <br />
                              <a
                                  href="https://clemensbuchegger.com"
                                  target="_blank"
                              >
                                  clemensbuchegger.com
                              </a>
                              <br />
                              <br />
                              Project Management
                              <br />
                              Katrin Aichele
                              <br />
                              Moranne Mintz
                              <br />
                              Christina Pethick
                              <br />
                              <br />
                              Production Management
                              <br />
                              Daniel Fusban
                              <br />
                              <br />
                              Research & Interview Coordination
                              <br />
                              Ariana Berndl
                              <br />
                              <br />
                              Conceptual Advisor
                              <br />
                              Jonathan Touitou
                              <br />
                              <br />
                              Original English and German Poems
                              <br />
                              Abigail Willoughby
                              <br />
                              <br />
                              Stonemasonry
                              <br />
                              Claus Rehm
                              <br />
                              <br />
                              Greek Text Reading
                              <br />
                              Christian Firsching
                              <br />
                              <br />
                              Sound Editing
                              <br />
                              Frank Sauer
                              <br />
                              <br />
                              Text on the Project
                              <br />
                              Estee Balsam
                              <br />
                              <br />
                              English Text Editing
                              <br />
                              Einat Adi
                              <br />
                              <br />
                              English Proofreading
                              <br />
                              Magdalen Chua
                              <br />
                              <br />
                              German Proofreading
                              <br />
                              Katharina Wulffius
                              <br />
                              <br />
                              Legal Advisor
                              <br />
                              Felix Stang
                              <br />
                              <br />
                              Commissioned by
                              <br />
                              City of Munich Building Department
                              <br />
                              Quivid – The Art in Buildings Program of the City
                              of Munich
                              <br />
                              <br />
                              Student Collaborators
                              <br />
                              Felix Aschenbrenner
                              <br />
                              Johanna Fuchs
                              <br />
                              Nana Kammann
                              <br />
                              Charlotte Lemke
                              <br />
                              Ekaterini Michales
                              <br />
                              Emilian Röckemann
                              <br />
                              Amélie-Nejd Saleh
                              <br />
                              Pia Scheneider Gonzáles
                              <br />
                              Luis Schmieder
                              <br />
                              Gabriela Tultschin
                              <br />
                              <br />
                              Special thanks to the teachers and staff of
                              Max-Gymnasium, whose help and support have made
                              this project possible.
                              <br />
                          </>
                      )}
                  </div>
              </div>

              <div className="about-content-item">
                  <div className="about-content-title"></div>
                  <div className="about-content-text"></div>
              </div>

              <div className="about-content-item">
                  <div className="about-content-title"></div>
                  <div className="about-content-text"></div>
              </div>
          </div>
      </div>
  );
};