import LocalizedStrings from "localized-strings";

export const PlayerStrings = new LocalizedStrings({
  en: {
    click_object: "Click on an object!",
  },
  de: {
    click_object: "Klicke ein Objekt!",
  },
});
