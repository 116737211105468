import React from "react";
import { daStore } from "../store";
import {
  MediaPermissionsError,
  MediaPermissionsErrorType,
  requestMediaPermissions,
} from "mic-check";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Button,
  CircularProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Link,
  Typography,
} from "@mui/material";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import Bowser from "bowser";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { OnboardingStrings } from "./OnboardingStrings";

const theme = createTheme({
  typography: {
    allVariants: {
      fontFamily: "GreyLLSubPan",
      textTransform: "none",
      fontWeight: 350,
    },
  },
});

const browser = Bowser.getParser(window.navigator.userAgent);

export const Onboarding = (props) => {
  const [isBoarded, setIsBoarded] = daStore.useState("isBoarded");
  const [lang, setLang] = daStore.useState("lang");
  const [errorDetails, setErrorDetails] = daStore.useState("arError");

  const [showDialog, setShowDialog] = React.useState(false);
  //const [errorDetails, setErrorDetails] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const handleStartClick = (e) => {
    //setShowDialog('systemDenied')
    setIsLoading(true);
    checkMediaPermissions();
  };

  const checkMediaPermissions = () => {
    // TODO: listen to if there is a change on the audio/video piece?
    requestMediaPermissions({ audio: false, video: true })
      .then(() => {
        if (window.debug) {
          console.log("camera access success");
        }
        setShowDialog(false);
        setIsLoading(false);
        setIsBoarded(true);
      })
      .catch((err) => {
        const { type, name, message } = err;
        if (window.debug) {
          console.log("MediaOnboardingDialog: ", err);
        }
        setIsLoading(false);
        setErrorDetails(err);
        if (type === MediaPermissionsErrorType.SystemPermissionDenied) {
          // browser does not have permission to access camera or microphone
          setShowDialog("systemDenied");
        } else if (type === MediaPermissionsErrorType.UserPermissionDenied) {
          // user didn't allow app to access camera or microphone
          setShowDialog("userDenied");
        } else if (
          type === MediaPermissionsErrorType.CouldNotStartVideoSource
        ) {
          // camera is in use by another application (Zoom, Skype) or browser tab (Google Meet, Messenger Video)
          // (mostly Windows specific problem)
          setShowDialog("trackError");
        } else {
          // not all error types are handled by this library
        }
      });
  };

  const _renderTryAgain = (text) => {
    return (
      <DialogActions>
        <Button
          onClick={() => {
            if (browser.getBrowserName() === "Safari") {
              // If on Safari, rechecking permissions results in glitches so just refresh the page
              window.location.reload();
            } else {
              setIsLoading(true);
              checkMediaPermissions();
            }
          }}
          color="primary"
          style={{ float: "right" }}
        >
          {text ? text : OnboardingStrings.retry}
        </Button>
      </DialogActions>
    );
  };

  const _renderErrorMessage = () => {
    if (!errorDetails) return null;
    return (
      <div style={{ marginTop: 10 }}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreRoundedIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography variant="caption" style={{ color: "red" }}>
              {OnboardingStrings.error_details}
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography variant="caption">
              {errorDetails.name}: {errorDetails.message}
            </Typography>
          </AccordionDetails>
        </Accordion>
      </div>
    );
  };

  const _renderSystemDeniedDialog = () => {
    const settingsDataByOS = {
      macOS: {
        name: "System Preferences",
        link: "x-apple.systempreferences:com.apple.preference.security?Privacy_Camera",
      },
    };

    return (
      <Dialog open={true}>
        <DialogTitle id="alert-dialog-title">
          {OnboardingStrings.track_error_title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {OnboardingStrings.system_denied_browser}{" "}
            {
              // @ts-ignore
              settingsDataByOS[browser.getOSName()] ? (
                <Link
                  onClick={() => {
                    window.open(
                      // @ts-ignore
                      settingsDataByOS[browser.getOSName()].link,
                      "_blank"
                    );
                  }}
                >
                  {
                    // @ts-ignore
                    settingsDataByOS[browser.getOSName()].name
                  }
                </Link>
              ) : (
                "Settings"
              )
            }
            .
          </DialogContentText>
        </DialogContent>
        {_renderErrorMessage()}
        {_renderTryAgain()}
      </Dialog>
    );
  };

  const _renderUserDeniedDialog = () => {
    return (
      <Dialog open={true}>
        <DialogTitle id="alert-dialog-title">
          {OnboardingStrings.user_denied_access_title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {OnboardingStrings.user_denied_access}{" "}
            {browser.getBrowserName() !== "Safari" && (
              <>
                {OnboardingStrings.user_denied_access_click}{" "}
                <img
                  alt="icon"
                  src={
                    "https://www.gstatic.com/meet/ic_blocked_camera_dark_f401bc8ec538ede48315b75286c1511b.svg"
                  }
                  style={{ display: "inline" }}
                />{" "}
                {OnboardingStrings.user_denied_access_browser}
              </>
            )}
            {_renderErrorMessage()}
          </DialogContentText>
        </DialogContent>
        {_renderTryAgain()}
      </Dialog>
    );
  };

  const _renderTrackErrorDialog = () => {
    return (
      <Dialog open={true}>
        <DialogTitle id="alert-dialog-title">
          {OnboardingStrings.track_error_title}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {OnboardingStrings.track_error}
          </DialogContentText>
        </DialogContent>
        {_renderErrorMessage()}
        {_renderTryAgain()}
      </Dialog>
    );
  };

  const _renderDialogContent = () => {
    switch (showDialog) {
      case "systemDenied":
        return _renderSystemDeniedDialog();
      case "userDenied":
        return _renderUserDeniedDialog();
      case "trackError":
        return _renderTrackErrorDialog();
    }
  };

  OnboardingStrings.setLanguage(lang);

  return (
    <ThemeProvider theme={theme}>
      <div className="onboarding">
        {isLoading && <CircularProgress />}

        {!isLoading && showDialog && _renderDialogContent()}

        {!isLoading && (
          <div className="onboarding-notice" onClick={handleStartClick}>
            {OnboardingStrings.welcome}
            <br />
            <br />
            {OnboardingStrings.access}
            <br />
            <br />
            <span className="start-button">Start</span>
          </div>
        )}
      </div>
    </ThemeProvider>
  );
};
