import LocalizedStrings from "localized-strings";

export const WebsiteAppStrings = new LocalizedStrings({
  en: {
    munich: "Munich",
    imprint: "Imprint",
  },
  de: {
    munich: "München",
    imprint: "Impressum",
  },
});
