import LocalizedStrings from "localized-strings";

export const HeaderStrings = new LocalizedStrings({
  en: {
    hide: "Hide",
    munich: "Munich",
    show: "Show",
    objects: "objects",
  },
  de: {
    hide: "verbergen",
    munich: "München",
    show: "anzeigen",
    objects: "Objekte",
  },
});
