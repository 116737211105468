import React from "react";
import { FocusObject } from "./FocusObject.jsx";
//import MindARDemo from './MindARDemo.jsx';
import MindARViewer from "./MindARViewer.jsx";
import { Player } from "./Player.jsx";

export const ArApp = (props) => {
  return (
    <div className="arapp">
      <FocusObject closeable="0"/>
      <MindARViewer />
      <Player />
    </div>
  );
};
